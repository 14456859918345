import React from "react"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

class EventAgenda extends React.Component {
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName("tabs_item")
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none"
    }

    tablinks = document.getElementsByTagName("li")
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "")
    }

    document.getElementById(tabNmae).style.display = "block"
    evt.currentTarget.className += "current"
  }
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      data2: [],
      data3: [],
      data4: [],
      loading: true,
      error: null,
    }
  }

  componentDidMount() {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
    const allData = `{
    "meta": {
        "code": 200,
        "status": "success",
        "message": "Data list agenda berhasil diambil"
    },
    "data": [
    {
      "id": 10,
      "title": "Opening Ceremony",
      "description": "Opening Act by TEAM RORO", 
      "start_time": "09:30:00",
      "end_time": "10:30:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 11, 
      "title": "Talkshow SME's",
      "description": "Ecofashion: Pioneering Sustainable Fashion in a Circular Economy",
      "start_time": "11:00:00",
      "end_time": "12:00:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 12,
      "title": "Talkshow Tourism",
      "description": "“Discovering Indonesia’s Top 5 Sustainable Tourism Destinations: Borobudur, Lake Toba, Mandalika, Raja Ampat, and Likupang”",
      "start_time": "12:30:00", 
      "end_time": "13:30:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 13,
      "title": "Music Performances / Playback Music ",
      "description": "",
      "start_time": "13:30:00",
      "end_time": "14:00:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 14,
      "title": "Talkshow SME's",
      "description": "Indonesian Restaurants in the Netherlands: Navigating Challenges and Opportunities in the Food Supply Chain",
      "start_time": "14:30:00",
      "end_time": "15:30:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 15,
      "title": "Talkshow Tourism",
      "description": "Sustainable Pathways: Exploring Eco-Tourism Villages & Regenerative Tourism Initiatives in Indonesia",
      "start_time": "15:45:00",
      "end_time": "16:45:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 18,
      "title": "Art Performances",
      "description": "NUSANTARA THEME: Stories of historical journeys, culture, diversity will be conveyed in a storytelling style that gives a deeper meaning to Indonesia more deeply, through songs, dances and narratives that will be performed.",
      "start_time": "17:00:00",
      "end_time": "17:10:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 19,
      "title": "Talkshow SME's",
      "description": "Indonesian Modest Fashion: Expanding Market Potential in Europe",
      "start_time": "10:30:00",
      "end_time": "11:30:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 20,
      "title": "Talkshow Tourism",
      "description": "The Hidden Gems of Indonesia: Unveiling Unique Travel Experiences for European Explorers",
      "start_time": "11:45:00",
      "end_time": "12:45:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 21,
      "title": "Entertainment Dance / Collab ",
      "description": "Sanggar Cendana X #TEAMRORO",
      "start_time": "13:15:00",
      "end_time": "13:45:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 22,
      "title": "Fun Activities",
      "description": "",
      "start_time": "13:45:00",
      "end_time": "14:45:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 23,
      "title": "Talkshow SME's",
      "description": "Indonesian Handicrafts, Home Decor & Furniture: Navigating Export Challenges and Opportunities in Europe",
      "start_time": "14:45:00",
      "end_time": "15:45:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 24,
      "title": "Talkshow Tourism",
      "description": "Bridging Continents: Boosting European Tourism to Indonesia through B2B Collaboration between Indonesia and The Netherlands",
      "start_time": "16:00:00",
      "end_time": "17:00:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 26,
      "title": "Music Performances / Playback Music ",
      "description": "",
      "start_time": "17:00:00",
      "end_time": "17:30:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 27,
      "title": "Cultural Parade",
      "description": "Cultural Parade of Indonesian Heritage (regional traditional clothes)",
      "start_time": "11:00:00",
      "end_time": "11:15:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 28,
      "title": "Traditional Indonesian dances",
      "description": "Bu Nova",
      "start_time": "11:15:00",
      "end_time": "11:20:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 29,
      "title": "Workshop ",
      "description": "Stichting Peduli Seni Indonesia",
      "start_time": "11:20:00",
      "end_time": "12:05:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 30,
      "title": "Music Performances / Playback Music ",
      "description": "",
      "start_time": "12:05:00",
      "end_time": "12:35:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 31,
      "title": "Workshop Jamu",
      "description": "Djamu-djamu",
      "start_time": "12:35:00",
      "end_time": "13:35:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 32,
      "title": "Fashion Show x Cultural Performances",
      "description": "Wastra Nusantara Fashion Show",
      "start_time": "13:35:00",
      "end_time": "14:35:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 32,
      "title": "Friendly Match with Lokapala",
      "description": "Ivan Chen - Lokapala",
      "start_time": "13:05:00",
      "end_time": "16:05:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 32,
      "title": "Cultural Performances",
      "description": "#TEAMRORO",
      "start_time": "16:05:00",
      "end_time": "16:15:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 32,
      "title": "Shadow Puppets Show",
      "description": "Ki Tetuko Aji",
      "start_time": "16:30:00",
      "end_time": "17:30:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 33,
      "title": "Fun Activities",
      "description": "Workshop Ichinogami",
      "start_time": "11:00:00",
      "end_time": "11:30:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 34,
      "title": "Shadow Puppets Show",
      "description": "Ki Tetuko Aji",
      "start_time": "12:00:00",
      "end_time": "13:00:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 35,
      "title": "Music Performances / Playback Music ",
      "description": "",
      "start_time": "13:15:00",
      "end_time": "13:45:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 36,
      "title": "Cultural Parade",
      "description": "Cultural Parade of Indonesian Heritage (regional traditional clothes)",
      "start_time": "13:45:00",
      "end_time": "14:00:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 37,
      "title": "Friendly Match with Lokapala",
      "description": "Ivan Chen - Lokapala",
      "start_time": "14:00:00",
      "end_time": "15:00:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 38,
      "title": "Announcement Competition ",
      "description": "Collabs Finale Tari Kreasi dan Team Roro",
      "start_time": "15:00:00",
      "end_time": "15:20:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 38,
      "title": "Modest Fashion Festival",
      "description": "Modest Fashion Show",
      "start_time": "15:50:00",
      "end_time": "16:50:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 38,
      "title": "Closing Ceremony",
      "description": "Treatment Closing: BALI TERIMA KASIH INDONESIA",
      "start_time": "16:50:00",
      "end_time": "17:35:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    }
  ]
}`
    const allData2 = JSON.parse(allData).data
    const dates = ["2024-11-28", "2024-11-29", "2024-11-30", "2024-12-01"]

    const sortByStartTime = (a, b) => {
      if (a.start_time < b.start_time) return -1
      if (a.start_time > b.start_time) return 1
      return 0
    }

    const [data, data2, data3, data4] = dates.map((date) =>
      allData2
        .filter((item) => item && item.date === date)
        .sort(sortByStartTime)
    )

    this.setState({
      data,
      data2,
      data3,
      data4,
      loading: false,
    })
  }
  render() {
    const { data, data2, data3, data4, loading, error } = this.state
    const formatTimeCEST = (time) => {
      const date = new Date(`1970-01-01T${time}`)
      return date.toLocaleTimeString("nl-NL", {
        hour: "2-digit",
        minute: "2-digit",
      })
    }

    const formatDate = (dateString) => {
      const { i18n } = this.props
      const date = new Date(dateString)
      return date.toLocaleDateString(i18n.language, {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    }
    const { t } = this.props

    if (loading) {
      return <div>{t("agenda.schedule.loading")}</div>
    }

    if (error) {
      return <div>{t("agenda.schedule.error")}</div>
    }

    return (
      <section className="schedule-area bg-image ptb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tab">
                <ul className="tabs active">
                  <li
                    onClick={(e) => this.openTabSection(e, "tab1")}
                    className="current"
                  >
                    <Link to="#">
                      {t("agenda.schedule.days.first")}
                      <span>{formatDate("2024-11-28")}</span>
                    </Link>
                  </li>

                  <li onClick={(e) => this.openTabSection(e, "tab2")}>
                    <Link to="#">
                      {t("agenda.schedule.days.second")}
                      <span>{formatDate("2024-11-29")}</span>
                    </Link>
                  </li>

                  <li onClick={(e) => this.openTabSection(e, "tab3")}>
                    <Link to="#">
                      {t("agenda.schedule.days.third")}
                      <span>{formatDate("2024-11-30")}</span>
                    </Link>
                  </li>

                  <li onClick={(e) => this.openTabSection(e, "tab4")}>
                    <Link to="#">
                      {t("agenda.schedule.days.fourth")}
                      <span>{formatDate("2024-12-01")}</span>
                    </Link>
                  </li>
                </ul>

                <div className="tab_content">
                  <div id="tab1" className="tabs_item">
                    <ul className="accordion">
                      {data.map((agenda) => (
                        <li key={agenda.id} className="accordion-item">
                          <p className="accordion-title" to="#">
                            {agenda.speakers && agenda.speakers.length > 0 && (
                              <div className="author author-multi">
                                {/* Assuming there might be multiple speakers, we're just selecting the first one here */}
                                {agenda.speakers.map((speaker) => (
                                  <img
                                    key={speaker.id}
                                    src={speaker.image}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={speaker.name}
                                    alt="Author"
                                  />
                                ))}
                              </div>
                            )}

                            <div className="schedule-list container">
                              <div className="schedule-item row border-bottom py-3">
                                <div className="col-md-9">
                                  <h3
                                    className="h5  font-weight-bold"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    {agenda.title}
                                  </h3>
                                  <p className="small text-muted">
                                    {agenda.description}
                                  </p>
                                </div>
                                <div className="col-md-3 text-md-right">
                                  <p
                                    className="h5  font-weight-bold mb-0"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    <i className="icofont-wall-clock text-success mr-2"></i>{" "}
                                    {formatTimeCEST(agenda.start_time)} -{" "}
                                    {formatTimeCEST(agenda.end_time)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </p>

                          <div className="accordion-content">
                            <p>{agenda.description}</p>

                            <div className="row h-100 align-items-center">
                              <div className="col-lg-6 col-md-7">
                                <div className="location">
                                  <b>Location:</b> {agenda.location}
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-5 text-right">
                                <Link to="#" className="btn btn-primary">
                                  View Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div id="tab2" className="tabs_item">
                    <ul className="accordion">
                      {data2.map((agenda) => (
                        <li key={agenda.id} className="accordion-item">
                          <Link className="accordion-title" to="#">
                            {agenda.speakers && agenda.speakers.length > 0 && (
                              <div className="author author-multi">
                                {/* Assuming there might be multiple speakers, we're just selecting the first one here */}
                                {agenda.speakers.map((speaker) => (
                                  <img
                                    key={speaker.id}
                                    src={speaker.image}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={speaker.name}
                                    alt="Author"
                                  />
                                ))}
                              </div>
                            )}

                            <div className="schedule-list container">
                              <div className="schedule-item row border-bottom py-3">
                                <div className="col-md-9">
                                  <h3
                                    className="h5  font-weight-bold"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    {agenda.title}
                                  </h3>
                                  <p className="small text-muted">
                                    {agenda.description}
                                  </p>
                                </div>
                                <div className="col-md-3 text-md-right">
                                  <p
                                    className="h5  font-weight-bold mb-0"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    <i className="icofont-wall-clock text-success mr-2"></i>{" "}
                                    {formatTimeCEST(agenda.start_time)} -{" "}
                                    {formatTimeCEST(agenda.end_time)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>

                          <div className="accordion-content">
                            <p>{agenda.description}</p>

                            <div className="row h-100 align-items-center">
                              <div className="col-lg-6 col-md-7">
                                <div className="location">
                                  <b>Location:</b> {agenda.location}
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-5 text-right">
                                <Link to="#" className="btn btn-primary">
                                  View Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div id="tab3" className="tabs_item">
                    <ul className="accordion">
                      {data3.map((agenda) => (
                        <li key={agenda.id} className="accordion-item">
                          <Link className="accordion-title" to="#">
                            {agenda.speakers && agenda.speakers.length > 0 && (
                              <div className="author author-multi">
                                {/* Assuming there might be multiple speakers, we're just selecting the first one here */}
                                {agenda.speakers.map((speaker) => (
                                  <img
                                    key={speaker.id}
                                    src={speaker.image}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={speaker.name}
                                    alt="Author"
                                  />
                                ))}
                              </div>
                            )}

                            <div className="schedule-list container">
                              <div className="schedule-item row border-bottom py-3">
                                <div className="col-md-9">
                                  <h3
                                    className="h5  font-weight-bold"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    {agenda.title}
                                  </h3>
                                  <p className="small text-muted">
                                    {agenda.description}
                                  </p>
                                </div>
                                <div className="col-md-3 text-md-right">
                                  <p
                                    className="h5  font-weight-bold mb-0"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    <i className="icofont-wall-clock text-success mr-2"></i>{" "}
                                    {formatTimeCEST(agenda.start_time)} -{" "}
                                    {formatTimeCEST(agenda.end_time)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>

                          <div className="accordion-content">
                            <p>{agenda.description}</p>

                            <div className="row h-100 align-items-center">
                              <div className="col-lg-6 col-md-7">
                                <div className="location">
                                  <b>Location:</b> {agenda.location}
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-5 text-right">
                                <Link to="#" className="btn btn-primary">
                                  View Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div id="tab4" className="tabs_item">
                    <ul className="accordion">
                      {data4.map((agenda) => (
                        <li key={agenda.id} className="accordion-item">
                          <Link className="accordion-title" to="#">
                            {agenda.speakers && agenda.speakers.length > 0 && (
                              <div className="author author-multi">
                                {/* Assuming there might be multiple speakers, we're just selecting the first one here */}
                                {agenda.speakers.map((speaker) => (
                                  <img
                                    key={speaker.id}
                                    src={speaker.image}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={speaker.name}
                                    alt="Author"
                                  />
                                ))}
                              </div>
                            )}

                            <div className="schedule-list container">
                              <div className="schedule-item row border-bottom py-3">
                                <div className="col-md-9">
                                  <h3
                                    className="h5  font-weight-bold"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    {agenda.title}
                                  </h3>
                                  <p className="small text-muted">
                                    {agenda.description}
                                  </p>
                                </div>
                                <div className="col-md-3 text-md-right">
                                  <p
                                    className="h5  font-weight-bold mb-0"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    <i className="icofont-wall-clock text-success mr-2"></i>{" "}
                                    {formatTimeCEST(agenda.start_time)} -{" "}
                                    {formatTimeCEST(agenda.end_time)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>

                          <div className="accordion-content">
                            <p>{agenda.description}</p>

                            <div className="row h-100 align-items-center">
                              <div className="col-lg-6 col-md-7">
                                <div className="location">
                                  <b>Location:</b> {agenda.location}
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-5 text-right">
                                <Link to="#" className="btn btn-primary">
                                  View Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="btn-box">
                <Link to="#" className="btn btn-primary">
                  {t("agenda.schedule.downloadPdf")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
        </div>
        <div className="shape2 rotateme">
          <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
        </div>
        <div className="shape3 rotateme">
          <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
        </div>
        <div className="shape4">
          <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
        </div>
      </section>
    )
  }
}

export default withTranslation()(EventAgenda)
