import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import lax from "lax.js"
import OwlCarousel from "react-owl-carousel3"
import PlatinumSponsors from "../LaxButton/PlatinumSponsors"
import GoldSponsors from "../LaxButton/GoldSponsors"
// import Footer from "../Common/Footer";
import { useTranslation } from "react-i18next"
import i18n from "i18next"
import FooterV2 from "../v2/layout/FooterV2"
import PartnerV2 from "../v2/common/PartnerV2"

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1200: {
      items: 5,
    },
  },
}

function Sponsor() {
  const [sponsors, setSponsors] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    lax.setup()
    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    const fetchSponsors = async () => {
      try {
        const sponsorsData = `{
    "meta": {
        "code": 200,
        "status": "success",
        "message": "Data list Sponsor berhasil diambil"
    },
    "data": {
        "current_page": 1,
        "data": [
            {
                "id": 1,
                "email": null,
                "phone": null,
                "name": "Negeriku",
                "image": "https://switchbox.id/wp-content/uploads/2023/09/logo.png",
                "link": "https://negeriku.nl/",
                "type": "organized",
                "status": "active",
                "created_at": "2024-08-25T13:31:14.000000Z",
                "updated_at": "2024-08-25T13:31:14.000000Z"
            },
            {
                "id": 2,
                "email": null,
                "phone": null,
                "name": "Kupu",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/kupu.png",
                "link": "https://www.kupu-gsc.co.id/",
                "type": "co-organized",
                "status": "active",
                "created_at": "2024-08-25T13:31:15.000000Z",
                "updated_at": "2024-08-25T13:31:15.000000Z"
            },
            {
                "id": 3,
                "email": null,
                "phone": null,
                "name": "KBRI Den Haag",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/embassy.jpg",
                "link": "https://indonesia.nl/id/",
                "type": "supported",
                "status": "active",
                "created_at": "2024-08-25T13:31:16.000000Z",
                "updated_at": "2024-08-25T13:31:16.000000Z"
            },
            {
                "id": 4,
                "email": null,
                "phone": null,
                "name": "Kemenparekraf",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/ministry.png",
                "link": "https://kemenparekraf.go.id/",
                "type": "supported",
                "status": "active",
                "created_at": "2024-08-25T13:31:18.000000Z",
                "updated_at": "2024-08-25T13:31:18.000000Z"
            },
            {
                "id": 5,
                "email": null,
                "phone": null,
                "name": "KADIN",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/KV-09.png",
                "link": "https://kadin.id/",
                "type": "supported",
                "status": "active",
                "created_at": "2024-08-25T13:31:19.000000Z",
                "updated_at": "2024-08-25T13:31:19.000000Z"
            },
            {
                "id": 6,
                "email": null,
                "phone": null,
                "name": "ASPINA",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/KV-04.png",
                "link": "https://aspina.nl/",
                "type": "supported",
                "status": "active",
                "created_at": "2024-08-25T13:31:20.000000Z",
                "updated_at": "2024-08-25T13:31:20.000000Z"
            },
            {
                "id": 7,
                "email": null,
                "phone": null,
                "name": "GCI",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/green-climate.png",
                "link": "http://greenclimateinternational.org",
                "type": "supported",
                "status": "active",
                "created_at": "2024-08-25T13:31:21.000000Z",
                "updated_at": "2024-08-25T13:31:21.000000Z"
            },
            {
                "id": 8,
                "email": null,
                "phone": null,
                "name": "Inisiatif Indonesia",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/KV-05.png",
                "link": "https://www.instagram.com/inisiatif_indo/",
                "type": "supported",
                "status": "active",
                "created_at": "2024-08-25T13:31:22.000000Z",
                "updated_at": "2024-08-25T13:31:22.000000Z"
            },
            {
                "id": 9,
                "email": null,
                "phone": null,
                "name": "PERTAMINA",
                "image": "https://www.pertamina.com/Media/Image/Pertamina.png",
                "link": "https://www.pertamina.com/",
                "type": "sponsored",
                "status": "active",
                "created_at": "2024-08-25T13:31:23.000000Z",
                "updated_at": "2024-08-25T13:31:23.000000Z"
            },
            {
                "id": 10,
                "email": null,
                "phone": null,
                "name": "Atourin",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/atourin.png",
                "link": "https://atourin.com/",
                "type": "partner",
                "status": "active",
                "created_at": "2024-08-25T13:31:25.000000Z",
                "updated_at": "2024-08-25T13:31:25.000000Z"
            },
            {
                "id": 11,
                "email": null,
                "phone": null,
                "name": "Pandi",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/logo-PANDI.png",
                "link": "https://pandi.id/",
                "type": "partner",
                "status": "active",
                "created_at": "2024-08-25T13:31:26.000000Z",
                "updated_at": "2024-08-25T13:31:26.000000Z"
            },
            {
                "id": 12,
                "email": null,
                "phone": null,
                "name": "Goorita",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/goorita.png",
                "link": "https://goorita.com/",
                "type": "partner",
                "status": "active",
                "created_at": "2024-08-25T13:31:27.000000Z",
                "updated_at": "2024-08-25T13:31:27.000000Z"
            },
            {
                "id": 13,
                "email": null,
                "phone": null,
                "name": "Travel Buddies",
                "image": "https://travelbuddies.co.id/wp-content/uploads/2021/12/logo-new.png",
                "link": "https://travelbuddies.co.id/",
                "type": "partner",
                "status": "active",
                "created_at": "2024-08-25T13:31:28.000000Z",
                "updated_at": "2024-08-25T13:31:28.000000Z"
            },
            {
                "id": 14,
                "email": null,
                "phone": null,
                "name": "Asidewi",
                "image": "https://asidewi.id/wp-content/uploads/2021/04/Logo-Asidewi.png",
                "link": "http://asidewi.id/",
                "type": "association",
                "status": "active",
                "created_at": "2024-08-25T13:31:29.000000Z",
                "updated_at": "2024-08-25T13:31:29.000000Z"
            },
            {
                "id": 15,
                "email": null,
                "phone": null,
                "name": "KEMBERIN",
                "image": "https://kemberin.org/wp-content/uploads/2021/08/logo-full-default.png",
                "link": "https://kemberin.org/",
                "type": "association",
                "status": "active",
                "created_at": "2024-08-25T13:31:30.000000Z",
                "updated_at": "2024-08-25T13:31:30.000000Z"
            },
            {
                "id": 16,
                "email": null,
                "phone": null,
                "name": "Kutus kutus",
                "image": "https://kutusofficial.com/wp-content/uploads/2019/08/logokutusofficial.png",
                "link": "https://kutusofficial.com/",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:32.000000Z",
                "updated_at": "2024-08-25T13:31:32.000000Z"
            },
            {
                "id": 17,
                "email": null,
                "phone": null,
                "name": "S.id",
                "image": "https://home.s.id/images/sid-neu-logo-dark.svg",
                "link": "https://home.s.id/id",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:33.000000Z",
                "updated_at": "2024-08-25T13:31:33.000000Z"
            },
            {
                "id": 18,
                "email": null,
                "phone": null,
                "name": "Sarinah",
                "image": "https://sarinah.co.id/cfind/source/thumb/images/cover_w276_h67_logo_sarinah_tanpa_tagline-png.png",
                "link": "https://sarinah.co.id/",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:34.000000Z",
                "updated_at": "2024-08-25T13:31:34.000000Z"
            },
            {
                "id": 19,
                "email": null,
                "phone": null,
                "name": "Suwe Ora Jamu",
                "image": "https://static.wixstatic.com/media/154aa2_7c70417d33f643efb371f313a6e4ca5b~mv2_d_1417_1417_s_2.png/v1/fill/w_240,h_171,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/154aa2_7c70417d33f643efb371f313a6e4ca5b~mv2_d_1417_1417_s_2.png",
                "link": "https://www.suweorajamu28.com/",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:35.000000Z",
                "updated_at": "2024-08-25T13:31:35.000000Z"
            },
            {
                "id": 20,
                "email": null,
                "phone": null,
                "name": "Ina Harmoni Travel",
                "image": "https://res.cloudinary.com/dvnpm2hid/image/upload/v1694490561/sponsor/xaoj7vjwagwlnrwmjswu.jpg",
                "link": "https://www.instagram.com/inaharmonitravel/?hl=en",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:36.000000Z",
                "updated_at": "2024-08-25T13:31:36.000000Z"
            },
            {
                "id": 21,
                "email": null,
                "phone": null,
                "name": "Konawe Selatan",
                "image": "https://konaweselatankab.go.id/img/logo_konsel.png",
                "link": "https://konaweselatankab.go.id/",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:37.000000Z",
                "updated_at": "2024-08-25T13:31:37.000000Z"
            },
            {
                "id": 22,
                "email": null,
                "phone": null,
                "name": "Buru Selatan",
                "image": "https://organisasi.burselkab.go.id/wp-content/uploads/2022/07/Lambang_Kabupaten_Buru_Selatan.png",
                "link": "https://burselkab.go.id/",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:39.000000Z",
                "updated_at": "2024-08-25T13:31:39.000000Z"
            },
            {
                "id": 23,
                "email": null,
                "phone": null,
                "name": "Belitung",
                "image": "https://belitung.go.id/images/logo-pemda.png",
                "link": "https://belitung.go.id/",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:40.000000Z",
                "updated_at": "2024-08-25T13:31:40.000000Z"
            },
            {
                "id": 24,
                "email": "-",
                "phone": "-",
                "name": "IFTA",
                "image": "https://res.cloudinary.com/dvnpm2hid/image/upload/v1725553868/sponsor/sponsor_24.png",
                "link": null,
                "type": "association",
                "status": "active",
                "created_at": "2024-09-05T16:28:53.000000Z",
                "updated_at": "2024-09-05T16:31:10.000000Z"
            },
            {
                "id": 26,
                "email": "bca@dmiexpo.id",
                "phone": "00000000",
                "name": "BCA",
                "image": "https://res.cloudinary.com/dvnpm2hid/image/upload/v1728297000/sponsor/sponsor_26.png",
                "link": null,
                "type": "supporting-sponsor",
                "status": "active",
                "created_at": "2024-10-07T04:24:22.000000Z",
                "updated_at": "2024-10-07T10:30:01.000000Z"
            },
            {
                "id": 27,
                "email": "lpei@dmiexpo.id",
                "phone": "000000000",
                "name": "LPEI",
                "image": "https://res.cloudinary.com/dvnpm2hid/image/upload/v1728910366/sponsor/sponsor_27.jpg",
                "link": null,
                "type": "supporting-sponsor",
                "status": "active",
                "created_at": "2024-10-14T12:44:50.000000Z",
                "updated_at": "2024-10-14T12:52:47.000000Z"
            }
        ],
        "first_page_url": "http://127.0.0.1:8000/api/sponsor?page=1",
        "from": 1,
        "last_page": 1,
        "last_page_url": "http://127.0.0.1:8000/api/sponsor?page=1",
        "links": [
            {
                "url": null,
                "label": "&laquo; Previous",
                "active": false
            },
            {
                "url": "http://127.0.0.1:8000/api/sponsor?page=1",
                "label": "1",
                "active": true
            },
            {
                "url": null,
                "label": "Next &raquo;",
                "active": false
            }
        ],
        "next_page_url": null,
        "path": "http://127.0.0.1:8000/api/sponsor",
        "per_page": 100,
        "prev_page_url": null,
        "to": 26,
        "total": 26
    }
}`
        const sponsorsData2 = JSON.parse(sponsorsData).data.data
        const categorizedSponsors = sponsorsData2.reduce((acc, sponsor) => {
          if (!acc[sponsor.type]) {
            acc[sponsor.type] = []
          }
          acc[sponsor.type].push(sponsor)
          return acc
        }, {})

        setSponsors(categorizedSponsors)
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching sponsors", error)
        setIsLoading(false)
      }
    }

    fetchSponsors()
  }, [])

  if (isLoading) {
    return <div>{t("sponsor.loading")}</div>
  }

  return (
    <React.Fragment>
      <div className="page-title-area item-bg2">
        <div className="container">
          <h1>{t("sponsor.title")}</h1>
          <span>{t("sponsor.subtitle")}</span>
          <ul>
            <li>
              <Link to="/">{t("sponsor.breadcrumb.home")}</Link>
            </li>
            <li>{t("sponsor.breadcrumb.sponsors")}</li>
          </ul>
        </div>
      </div>

      <section className="partner-area ptb-120">
        <div className="container">
          <div className="row">
            {i18n.language !== "id" && (
              <div className="col-lg-12">
                <img
                  style={{ maxHeight: "70%" }}
                  src={require("../../assets/images/PERTAMINA.png")}
                  alt={`Pertamina`}
                />
                <h1>
                  Pertamina TJSL Update: SUSTAINABLE SELF-SUFFICIENT COMMUNITY
                  THROUGH VILLAGE TOURISM PROGRAM
                </h1>
                {/* <img src={require("../../assets/PERTAMINA.png")} /> */}

                <div className="row">
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      The Village Tourism Program, as part of{" "}
                    </span>
                    <strong>
                      Pertamina&rsquo;s Social &amp; Environmental
                      Responsibility (TJSL)
                    </strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      program in the environmental and economic pillars,
                      encourages the strengthening of skills (upskilling) and
                      capacity building in managing community-based sustainable
                      tourism. The Pertamina Tourism Village Program is an
                      implementation of ESG aspects and supports the{" "}
                    </span>
                    <strong>
                      Sustainable Development Goals (SDGs) point 8, point 11,
                      and point 15.
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      The Ministry of Tourism and Creative Economy of the
                      Republic of Indonesia nominated the Tourism Village as one
                      of 16 Sustainable Tourism Villages and in the top 50 for
                      Indonesia's Best Tourism Village Award. Pertamina also
                      received the Bronze Category award for its community-based
                      tourism village development program from the Ministry of
                      Villages, Development of Disadvantaged Regions, and
                      Transmigration.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>As a</span>
                    <strong> platinum sponsor</strong>
                    <span style={{ fontWeight: 400 }}> of the </span>
                    <strong>
                      Discovering The Magnificence of Indonesia (DMI Expo) 2024
                    </strong>
                    <span style={{ fontWeight: 400 }}>
                      event, Pertamina&rsquo;s involvement goes beyond financial
                      support. They are actively participating in the event
                      program, reinforcing their commitment to fostering
                      sustainable tourism. By supporting village tourism
                      initiatives at
                    </span>
                    <strong>
                      Discovering The Magnificence of Indonesia (DMI Expo) 2024
                    </strong>
                    <span style={{ fontWeight: 400 }}>
                      , Pertamina not only showcases Indonesia's rich cultural
                      and natural heritage but also promotes the empowerment of
                      local communities through tourism. This collaboration
                      aligns with Pertamina's focus on creating self-sufficient
                      communities, as it helps bring international exposure to
                      Indonesia's tourism villages, paving the way for broader
                      economic opportunities and sustainable growth.
                    </span>
                  </p>
                </div>
              </div>
            )}
            {i18n.language === "id" && (
              <div className="col-lg-12">
                <img
                  style={{ maxHeight: "70%" }}
                  src={require("../../assets/images/PERTAMINA.png")}
                  alt={`Pertamina`}
                />
                <h1>
                  Pertamina Mendukung Penuh Discovering the Magnificence of
                  Indonesia Expo 2024: Pertamina Dorong Ekspansi Pariwisata dan
                  UKM Indonesia
                </h1>
                {/* <img src={require("../../assets/PERTAMINA.png")} /> */}

                <div className="row">
                  <p>
                    <strong>
                      <em>
                        Discovering The Magnificence of Indonesia (DMI Expo)
                        2024
                      </em>
                    </strong>{" "}
                    <span style={{ fontWeight: 400 }}>
                      dengan bangga menerima dukungan penuh dari{" "}
                    </span>
                    <strong>PT Pertamina (Persero)</strong>
                    <span style={{ fontWeight: 400 }}> sebagai </span>
                    <strong>
                      <em>Platinum Sponsor</em>
                    </strong>
                    <span style={{ fontWeight: 400 }}>
                      . Dengan adanya dukungan finansial dari Pertamina, kami
                      memiliki kesempatan untuk menghadirkan pameran yang lebih
                      besar dan berkualitas, serta memperkuat upaya kami dalam
                      mempromosikan pariwisata dan UKM Indonesia di pasar Eropa.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Selain dukungan finansial, keterlibatan Pertamina dalam
                      DMI 2024 juga terlihat melalui partisipasi aktif mereka
                      dalam program acara di{" "}
                    </span>
                    <strong>
                      <em>
                        Discovering The Magnificence of Indonesia (DMI Expo)
                        2024
                      </em>
                    </strong>
                    <span style={{ fontWeight: 400 }}>
                      . Pertamina akan berbagi wawasan tentang inovasi energi
                      dan keberlanjutan, sejalan dengan komitmen mereka untuk
                      mendukung pariwisata berkelanjutan dan pengembangan UKM
                      yang lebih ramah lingkungan. Kehadiran Pertamina dalam DMI
                      juga menjadi simbol sinergi antara sektor energi dan
                      pariwisata, yang dapat menciptakan peluang baru bagi
                      kolaborasi lintas sektor di masa depan.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Dukungan ini memungkinkan kami untuk lebih fokus
                      mengembangkan berbagai program menarik yang dapat
                      menghubungkan pelaku pariwisata dan UKM dengan peluang
                      bisnis internasional. Dengan kehadiran Pertamina sebagai
                      mitra utama, kami tidak hanya mendapatkan dukungan
                      finansial, tetapi juga penguatan dalam kolaborasi
                      strategis yang mencakup sektor industri dan pemerintah.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Kami berharap, dengan dukungan dari Pertamina, lebih
                      banyak perusahaan dan mitra lainnya terinspirasi untuk
                      turut bergabung dalam mendukung{" "}
                    </span>
                    <strong>
                      <em>
                        Discovering The Magnificence of Indonesia (DMI Expo)
                        2024
                      </em>
                    </strong>
                    <span style={{ fontWeight: 400 }}>
                      . Kolaborasi seperti ini menjadi kunci penting untuk
                      memperkuat posisi Indonesia sebagai destinasi wisata dan
                      pusat produk UKM yang siap bersaing di kancah global.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Kami berterima kasih kepada Pertamina atas kepercayaan dan
                      komitmennya dalam mendukung visi kami. Dengan adanya
                      sinergi ini, kami optimis bahwa{" "}
                    </span>
                    <strong>
                      <em>
                        Discovering The Magnificence of Indonesia (DMI Expo)
                        2024{" "}
                      </em>
                    </strong>
                    <span style={{ fontWeight: 400 }}>
                      akan memberikan dampak signifikan, tidak hanya dalam
                      memperkenalkan keindahan pariwisata Indonesia, tetapi juga
                      dalam memperkuat ekonomi kreatif dan UKM Indonesia di mata
                      dunia.
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <PartnerV2 />
          </div>
          <div className="row mt-5">
            <section className="cta-area">
              <div className="container">
                <div className="row h-100 align-items-center">
                  <div className="col-lg-8">
                    <h3>{t("sponsor.becomeSponsor.title")}</h3>
                    <span>{t("sponsor.becomeSponsor.description")}</span>
                  </div>

                  <div className="col-lg-4 text-right">
                    <Link
                      to="sponsors-application"
                      className="btn btn-secondary animated-button-dark"
                    >
                      {t("sponsor.becomeSponsor.button")}
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
      <FooterV2 />
    </React.Fragment>
  )
}

export default Sponsor
