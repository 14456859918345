import CompassIcon from "./CompassIcon";
import CalendarIcon from "./CalendarIcon";
import "./PlaceAndDate.css";

const PlaceAndDate = () => {
  return (
    <ul className="place-date-list">
      <li className="place-date-item">
        <CompassIcon />
        Jaabeurs, Utrecht - Netherlands
      </li>
      <li className="place-date-item">
        <CalendarIcon />
        28<sup>th</sup> November 2024 - 1<sup>st</sup> December 2024
      </li>
    </ul>
  );
};

export default PlaceAndDate;
