import React, { useEffect } from "react"
import lax from "lax.js"
// import OwlCarousel from "react-owl-carousel3";
// import PlatinumSponsors from "../../LaxButton/PlatinumSponsors";
// import GoldSponsors from "../../LaxButton/GoldSponsors";
import { useTranslation } from "react-i18next"
import sponsorsData from "./SponsorData.json"
import "./PartnerV2.css"
import BecomeASponsorBtn from "./BecomeASponsorBtn"
import SponsorCard from "./SponsorCard"
import SponsorCarousel from "./SponsorCarousel"
import FunctionalLaxDiv from "../shared/FunctionalLaxDiv"

function PartnerV2() {
  const { t } = useTranslation()

  const sponsors = sponsorsData.reduce((acc, sponsor) => {
    if (!acc[sponsor.type]) {
      acc[sponsor.type] = []
    }
    acc[sponsor.type].push(sponsor)
    return acc
  }, {})

  useEffect(() => {
    lax.setup()
    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )
  }, [])

  return (
    <section className="partner-area-v2">
      <div className="container">
        <div className="partner-new-section-title">
          <h2>{t("sponsor.head")}</h2>

          <div className="lax-absolute">
            <FunctionalLaxDiv text="Sponsors" dataPreset="driftLeft" />
          </div>

          <BecomeASponsorBtn
            link={"sponsors-application"}
            text={t("sponsor.becomeSponsor.title")}
          />
        </div>

        <SponsorCard data={sponsors["organized"]} title="Hosted By" />
        <SponsorCard data={sponsors["co-organized"]} title="Organized By" />
        <SponsorCarousel data={sponsors["supported"]} title="Supported By" />
        <SponsorCard
          data={sponsors["sponsored"]}
          title="Gold Sponsor"
          isGoldSponsor
        />
        <SponsorCarousel
          data={sponsors["supporting-sponsor"]}
          title="Supporting Sponsor"
        />
        <SponsorCarousel data={sponsors.partner} title="Official Partner" />

        {sponsors["exhibitor"] && (
          <SponsorCarousel
            data={sponsors.association}
            title="Association Partner"
          />
        )}
      </div>
    </section>
  )
}

export default PartnerV2 // Remove withTranslation() since we're using the hook
